
.projects-container {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
    animation: fadeIn 1s ease-out;
  }
  
  .project-card {
    border: 1px solid #ccc;
    border-radius: 8px;
    overflow: hidden;
    width: calc(33.333% - 20px);
    display: flex;
    flex-direction: column;
    min-height: 350px;
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out; 
  }

  .project-card:hover {
    transform: translateY(-5px) scale(1.03);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  
  
  .project-image {
    width: 100%;
    height: 200px;
    object-fit: cover;
  }
  
  .project-details {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 15px;
  }
  
  .project-title {
    font-size: 1.25rem;
    margin-bottom: 10px;
  }
  
  .project-description {
    flex-grow: 1;
    margin-bottom: 10px;
  }
  
  .project-tools {
    margin-bottom: 10px;
  }
  
  .project-code-link {
    align-self: start;
    color: #0077ff;
    text-decoration: none;
    margin-top: auto;
  }

  @media (max-width: 768px) {
    .project-card {
      width: calc(50% - 20px);
      min-height: 300px;
    }
  }
  
  @media (max-width: 480px) {
    .project-card {
      width: calc(100% - 20px);
      min-height: 250px;
    }
  }
  