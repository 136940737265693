
.skills-container {
  margin: 4rem 0;
  position: relative;
  overflow: hidden;
}

.skills-container::after,
.skills-container::before {
  content: " ";
  width: 28rem;
  height: 28rem;
  background: #7c66e3;
  position: absolute;
  z-index: -1;
  opacity: 0.3;
  filter: blur(60px);
  -webkit-filter: blur(60px);
  border-radius: 50%;
}

.skills-container::after {
  top: -25%;
  left: -25%;
  width: 50%;
  height: 50%;
  background: #7c66e3;
}

.skills-container::before {
  bottom: -25%;
  right: -25%;
  width: 50%;
  height: 50%;
  background: #9e51b9;
}

.skills-container h5 {
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 3.5rem;
  padding: 0 2rem;
}

.skills-content {
  display: flex;
  align-items: flex-start;
  gap: 3rem;
}

.skills {
  flex: 1;
  display: grid;
  grid-gap: 3rem;
  grid-template-columns: repeat(2, 1fr);
  padding-left: 2rem;
}

.skills-info {
  flex: 1;
}

@media (max-width: 1025px) {
  .skills {
    padding-left: 1rem;
  }

  .skills,
  .skills-content {
    grid-gap: 2rem;
  }
}

@media (max-width: 768px) {
  .skills-container {
    margin: 2rem 0; 
  }
  .skills-content {
    flex-direction: column-reverse;
    gap: 3rem;
  }

  .skills,
  .skills-info {
    width: 100%;
  }

  .skills-container h5 {
    font-size: 1.3rem;
    margin-bottom: 2rem;
  }

  .skills-container::after,
  .skills-container::before {
    width: 70%;
    height: 70%;
    top: auto;
    left: auto;
    bottom: -35%;
    right: -35%;
  }
}


@media (max-width: 600px) {
  .skills-container {
    padding: 0;
  }
}
