
.experience-container {
   
    animation: fadeIn 1s ease-out;
    width: 100%;
  }
  
  .experience-content {
   
  }
  
  .experience-section {
    background-color: #FFFFFF;
    padding: 20px;
    border: 1px solid var(--border-color);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    margin-bottom: 2rem;
    width: 100%;
  }
  
 
  .experience-section:not(:last-child) {
    margin-bottom: 2rem;
  }

  