
  
.profile-header {
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: var(--bg-color);
    width: 100%;
}
  
.profile-header h1 {
  word-break: break-word;
}

.profile-header p {
  word-break: break-word;
  text-align: center;
  padding: 0 10px;
  max-width: 100%;
}
 
@media (max-width: 768px) {
  .profile-header {
    display: none;
  }
  
  .profile-picture {
    width: 150px;
    height: 150px;
  }
  
 
}

  .profile-header h1,
  .profile-header p {
    text-align: center;
    max-width: none;
  }


  .social-links {
    justify-content: start;
  }


 

  @media (max-width: 600px) {
    .profile-header {
     display: none;
    }
    
    .profile-picture {
      width: 120px;
      height: 120px;
    }
    
   
  }
  
  
 
  @media (max-width: 400px) {
    .profile-header {
     display: none;
    }
    
    .profile-picture {
      width: 100px;
      height: 100px;
    }
    
   
  }
  
 
  