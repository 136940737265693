/* eslint-disable */
.contact-container {
  display: flex;
  flex-direction: column;
  align-items: center; 
  justify-content: center;
  width: 100%;
  min-height: 100vh; 
  padding: 4rem 0; 
  gap: 2rem;
  margin-top: 2rem;
}

.contact-container h5 {
  text-align: center;
  width: 100%;
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 3rem;
}

.contact-content {
  width: 100%; 
  max-width: 800px; 
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 2rem; 
  align-items: center; 
}

@media (max-width: 768px) {
  .contact-content {
    flex-direction: column-reverse;
  }

  .contact-container h5 {
    font-size: 1.3rem;
    margin-bottom: 1.5rem;
  }
}
