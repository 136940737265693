

:root {
  --bg-color: #F4F5F7;
  --text-color: #333333;
  --accent-color: #3366CC;
  --border-color: #CCCCCC;
}

body, button, input, textarea {
  font-family: 'Roboto', sans-serif;
  color: var(--text-color);
}

.container {
  max-width: 1600px;
  margin: 0 auto;
  position: relative;
  background-color: var(--bg-color);
  padding: 0;
  overflow: hidden;
}

@media (max-width: 1300px) {
  .container {
    padding: 0 1.5rem;
  }
}

@media (max-width: 1380px) {
  .container {
    padding: 0 1.5rem;
  }
}
