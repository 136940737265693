
* {
  font-family: "Poppins", sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  color: var(--text-color);
  background-color: var(--bg-color);
  margin: 0;
}
