.about-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  animation: fadeInUp 1s ease-out;
  margin: 40px 0;
}

.about-content {
  max-width: 100%;
  width: 100%;
  padding: 20px;
  border: 1px solid var(--border-color);
  background-color: #FFFFFF;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  border-radius: 10px;
}

.about-content h1 {
  font-size: 2rem;
  font-weight: 600;
  color: var(--accent-color);
  margin-bottom: 1rem;
}

.about-content p {
  margin-bottom: 16px;
  font-size: 1.1rem;
  line-height: 1.8;
  color: var(--text-color);
 
}


.about-content p:not(:last-child) {
  margin-bottom: 16px;
}



.profile-header {
 
  width: 250px;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.profile-picture {
 
  width: 150px;
  height: 150px;
  border-radius: 50%;
  object-fit: cover;
}


@media (max-width: 768px) {
  .about-container {
    flex-direction: column;
  }

  .profile-header,
  .about-content {
    width: 100%;
  }

  .about-content {
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
  }
  
  .profile-header {
    order: 1;
    padding: 20px;
    margin-bottom: 20px;
  }

  .profile-picture {
    width: 120px;
    height: 120px;
    margin: 0 auto 20px; 
  }
}

@media (max-width: 600px) {
  .about-content {
    width: 100%;
    padding: 15px;
    box-sizing: border-box;
  }
  
  .about-content,
  .profile-header {
    padding: 15px;
  }

  .profile-picture {
    width: 110px;
    height: 110px;
  }
}

@media (max-width: 400px) {
  .about-content {
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
  }

  .about-content,
  .profile-header {
    padding: 10px;
  }

  .profile-picture {
   
    width: 90px;
    height: 90px;
  }

  .about-content {
    padding: 10px;
  }
}
  
