/* eslint-disable */
.skills-info-card {
  min-height: 23rem;
  border-radius: 0.65rem;
  border: 2px solid #29a8ab; 
  background: #2F80ED;
  backdrop-filter: blur(1rem);
}

.skills-info-card h6 {
  color: #ffffff;
  font-size: 1.2rem;
  font-weight: 500;
  padding: 0.8rem 2rem;
  background: linear-gradient(180deg, #ffffff 0%, #ffffff 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  border-bottom: 1.5px solid #ffffff;
}

.skills-info-content {
  padding: 2rem;
}

.skill-info {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.skill-info p {
  color: #ffffff;
  font-size: 1rem;
  font-weight: 500;
}

.skill-info .percentage {
  color: #ffffff;
}

.skill-progress-bg {
  width: 100%;
  height: 0.5rem;
  background: #2F80ED;
  border-radius: 0.5rem;
  margin: 1rem 0;
  overflow: hidden;
}

.skill-progress {
  width: 0%;
  height: 0.5rem;
  background: #ffffff;
  border-radius: 0.5rem;
  transition: all 0.5s ease-in-out;
}

@media (max-width: 1025px) {
  .skills-info-card h6 {
    font-size: 1.1rem;
  }

  .skill-info p {
    font-size: 0.85rem;
  }

  .skills-info-content {
    padding: 1.5rem;
  }

  .skill-progress-bg, .skill-progress {
    height: 0.3rem;
  }
}
