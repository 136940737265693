
  
.main-layout {
  display: flex;
  flex-direction: column;
  background-color: var(--bg-color);
  width: 100%;
}

  
  @media (min-width: 768px) {
    .main-layout {
      flex-direction: row;
    }
  }
  
  .main-content {
    flex: 1;
    padding: 20px;
    background-color: var(--bg-color);
    width: 100%;
  }

  @media (max-width: 600px) {
    .main-layout {
      padding: 0;
    }
  
    .main-content {
      padding: 15px;
      box-sizing: border-box;
      width: 100%;
      flex-grow: 1;
    }

    .profile-header {
      display: none !important;
    }
  }

  @media (max-width: 400px) {
    .main-layout {
      padding: 0;
    }
  
    .main-content {
      padding: 10px;
      width: 100%;
      flex-grow: 1;
     
    }

    .profile-header {
      display: none !important;
    }
  }
  