
* Shared styles for About and Education containers *
.page-container {
  display: flex;
  flex-direction: row;
  align-items: center; 
  animation: fadeInUp 1s ease-out;
  margin: 40px 0;
}

.education-section {
  padding: 20px;
  background-color: #FFFFFF;
  border: 1px solid var(--border-color);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  margin-bottom: 2rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

ul {
  padding-left: 20px;
  margin: 0;
}


ul {
  list-style-position: inside;
}


h2, p {
  margin: 0 0 10px 0;
}

.education-section:not(:last-child) {
  margin-bottom: 2rem; 
}

.main-layout {
  display: flex;
  flex-direction: row; 
  width: 100%;
}

@media (min-width: 768px) {
  .main-layout {
    flex-direction: row; 
  }
}




.profile-header {
  width: 250px;
  flex-shrink: 0; 
  display: flex;
  flex-direction: column;
  align-items: center;
}

.profile-picture {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  object-fit: cover; 
}

@media (max-width: 768px) {
  .page-container {
    flex-direction: column;
    align-items: flex-start; 
  }

  .education-section {
    width: auto; 
  }

   
  ul {
    padding-left: 20px;
  }
}