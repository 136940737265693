/* eslint-disable */
.contact-details-card {
  flex: 1;
  width: 100%;
  max-width: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #2F80ED;
  border-radius: 0.65rem;
  border: 1.5px solid #29a8ab;
  padding: 2rem;
  margin-bottom: 2rem;
} 

.contact-details-card .icon {
  width: 4rem;
  height: 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.5rem;
  background: #115cbe;
  margin-bottom: 0.8rem;
}

.icon img {
  width: 1.8rem;
  height: auto;
  object-fit: contain;
}

.contact-details-card p {
  font-size: 0.9rem;
  font-weight: 400;
  text-align: center;
}

.contact-details-card a {
  color: #FFFFFF;
  text-decoration: none;
}

.contact-details-card a:hover {
  text-decoration: underline;
}


@media (max-width: 400px) {
  .contact-details-card p {
    font-size: 0.8rem;
  }
}
